import React, { useEffect, useState } from 'react'
import WindowEml from '../WindowEml'
import {BsArrowLeft, BsArrowRight} from 'react-icons/bs'
import {IoMdRefresh} from 'react-icons/io'
import googleLogo from '../../assets/img/Google_logo_white_2015.png'
import {AiOutlineSearch} from 'react-icons/ai'
import axios from 'axios'

const GoogleChrome = ({ item, zIndex}) => {

    const [url, setUrl] = useState(null)
    const [i_frame_url, setI_frame_url] = useState(null)


    useEffect(() => {
        if(item.data){
            setI_frame_url(item.data.link)
            setUrl(item.data.link)
        }
    }, [item])
  return (
    <WindowEml item={item} title={'Google Chrome'} zIndex={zIndex} >
        <form 
                    onSubmit={(e) => {e.preventDefault(); setI_frame_url(url)}} 
        
        className='w-full p-2 bg-[#282C2F] flex items-center'>
            <div className='flex items-center'>
                <p className='text-gray-400 mx-1'><BsArrowLeft /></p>
                <p className='text-gray-400 mx-1'><BsArrowRight /></p>
                <p className='text-white mx-2'><IoMdRefresh /></p>

            </div>
            <input
            className='w-full rounded-full text-sm bg-[#202124] outline-none text-white px-2 py-1 focus:outline-1 focus:outline-blue-700'
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            />
        </form>
        {
            i_frame_url ? 
            <iframe src={i_frame_url.includes('http' || 'https') ? i_frame_url : `http://${i_frame_url}`} className='w-full h-full' />
            :
            <div className='w-full flex-1 h-full bg-[#323639] flex flex-col items-center'>
               <div className='w-1/4 '>
               <img src={googleLogo} />
               </div>
               <div className='w-1/3 -mt-10'>
                    <form
                    onSubmit={(e) => {e.preventDefault(); setI_frame_url(url)}} 
                    className='w-full rounded-full bg-white h-10 shadow-xl flex items-center px-3 overflow-hidden'>
                        <div><AiOutlineSearch /></div>
                        <input 
                        className='w-full h-full px-3 mx-1 outline-none'
                        placeholder='Type A URL...'
                        value={url}
                          onChange={(e) => setUrl(e.target.value)}
                        />
                    </form>
               </div>
        </div>
        }
    </WindowEml>
  )
}



export default GoogleChrome