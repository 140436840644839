import React from 'react'
import Selecto from "react-selecto";
import GoogleChrome from '../GoogleChrome';
import { connect } from 'react-redux';
import DocReader from '../DocReader';
import Alert from '../Alert';
import bg from '../../assets/img/2587476.webp'
import { ubuntuActions } from '../../redux/actions';

const MainCanvas = ({
    appsList,
    menu,
    setMenu
}) => {

   
  return (
    
    <div className={`w-full flex-1 h-full overflow-hidden flex relative ${menu ? 'rounded-3xl !absolute cursor-pointer hover:shadow-2xl' : ''} `} onClick={() => {if(menu)setMenu(false)}} style={{
        background: menu ? `url(${bg})` : '',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
     transform: menu ?  'scale(.20)' : 'scale(1)',
     top: menu ? '-30%' : '0'
     }}>
        
        {
            appsList?.length == 0 &&  <Selecto
            dragContainer={".mainCanvas"}
            selectableTargets={[".mainCanvas"]}
            hitRate={100}
            selectByClick={true}
            selectFromInside={true}
            ratio={0}
            onSelect={(e) => {
              e.added.forEach((el) => {
                el.classList.add("selected");
              });
              e.removed.forEach((el) => {
                el.classList.remove("selected");
              });
            }}
          ></Selecto>
        }

    <div className='flex-1 w-full h-full  flex relative mainCanvas' >
        {
            appsList.filter(itm => itm.relatedApp == 'Alert')?.map(item => <Alert item={item} />)
        }
         {
            appsList.filter(itm => itm.relatedApp == 'Google Chrome')?.map(item =>  <GoogleChrome item={item} data={item.data} zIndex={item.id + 10} key={item.id} />)
         }
            {
                 <DocReader key={appsList.find(itm => itm.relatedApp == "Doc Reader")?.id} item={appsList?.find(itm => itm.relatedApp == "Doc Reader")} zIndex={appsList.find(itm => itm.relatedApp == "Doc Reader")?.id + 10} />
            }
    </div>
    </div>
  )
}

const mapStateToProps = state => ({
appsList: state.ubuntuState.appsList,
menu: state.ubuntuState.menu
})
const mapDispatchToProps = {
setMenu: ubuntuActions.setMenu
}

export default connect(mapStateToProps, mapDispatchToProps)(MainCanvas)