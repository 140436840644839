import { portfolioData } from "../../Data/portfolio";


let initialState = {
    appsList: [],
    menu: false,
    menuList: portfolioData
}

 const ubuntuState = (state = initialState, action) => {
    let {type, payload} = action;

    switch(type){

        case 'OPEN_APP':
            return {
                ...state,
                appsList: [...state.appsList, payload]
            }
        
        case 'CLOSE_APP': 
        return  {
            ...state,
            appsList: state.appsList.filter(item => item.id !== payload.id)
        }

        case 'SET_MENU':
            return {
                ...state,
                menu: payload
            }


        default:
            return state
    }
}


export default ubuntuState