import React from 'react'
import resume from '../../assets/files/PedramKeshavarzi-min.pdf'

const MobilePage = () => {
  return (
    <div className='w-full h-screen'>
      <embed src={resume} className='w-full h-full' />

    </div>
  )
}

export default MobilePage