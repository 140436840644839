import React, { useRef, useState } from "react";
import WindowControls from "../WindowControls";
import { useDragging } from "../../utils/hooks/useDragging";
import { connect } from "react-redux";
import { ubuntuActions } from "../../redux/actions";

const WindowEml = ({ children, title, zIndex, item, className }) => {
  const [windowType, setWindowType] = useState("normal");
  const movableRef = useRef();
  const windowRef = useRef();
  const [controlRef, ref, x, y, isDragging] = useDragging();

  return (
    <>
      <div
        ref={ref}
        className={`  mx-auto flex flex-col shadow-xl

            ${className}
        ${
          windowType == "normal"
            ? "rounded-xl transition-none absolute overflow-hidden w-2/3 h-4/5 mt-8  window"
            : "w-full h-full flex-1 !relative top-0 right-0 translate-x-0 translate-y-0"
        }
    `}
        // draggable={true}
        onDrag={(e) => console.log(e)}
        style={{
          zIndex: zIndex,
          maxWidth: windowType == "normal" && "auto",
          maxHeight: windowType == "normal" && "auto",
          minWidth: windowType == "normal" && "auto",
          minHeight: windowType == "normal" && "auto",
          transform: windowType !== "normal" && "none",
          left: windowType == "normal" ? x : 0,
          top: windowType == "normal" ? y : 0,
        }}
      >
        <WindowControls
          controlRef={controlRef}
          item={item}
          title={title}
          windowType={windowType}
          setWindowType={setWindowType}
          zIndex={zIndex}
        />
        <div className="w-full flex-1 flex flex-col">{children}</div>
      </div>
      {/* {
    windowType == 'normal' && 
    <Moveable
    ref={movableRef}
    target={windowRef}
    draggable={true}
    // throttleDrag={1}
    edgeDraggable={false}
    startDragRotate={0}
    throttleDragRotate={0}
    // resizable={true}
    keepRatio={false}
    throttleResize={1}
    renderDirections={["nw","n","ne","w","e","sw","s","se"]}
    // rotatable={true}
    // throttleRotate={0}
    // rotationPosition={"top"}
    onDrag={e => {
        e.target.style.transform = e.transform;
    }}
    // onResize={e => {
    //     e.target.style.width = `${e.width}px`;
    //     e.target.style.height = `${e.height}px`;
    //     e.target.style.transform = e.drag.transform;
    // }}
   
/>
   } */}
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WindowEml);
