import React from 'react'
import {FaRegWindowMaximize, FaRegWindowMinimize,FaRegWindowRestore, FaRegWindowClose} from 'react-icons/fa'
import { connect } from 'react-redux'
import { ubuntuActions } from '../../redux/actions'

const WindowControls = ({
    title,
    windowType,
    setWindowType,
    closeApp,
    zIndex,
    item,
    controlRef
}) => {
    
  return (
    <div ref={controlRef} className='w-full  bg-ubuntuGray flex items-center justify-between px-2 py-1'>
        <div>

        </div>
        <div>
            <p className='text-white'>{title}</p>
        </div>
            <div className='grid grid-cols-2 gap-4 text-gray-300 hover:[&>p]:bg-gray-500 [&>p]:p-1 [&>p]:rounded-full'>
               
                <p onClick={() => {
                    if(windowType == 'normal'){
                        setWindowType('full')
                    }else{
                        setWindowType('normal')
                    }
                }}>{
                    windowType == 'normal' ? <FaRegWindowMaximize /> : <FaRegWindowRestore  />
}</p>
                <p onClick={() => closeApp(item)}><FaRegWindowClose /></p>
            </div>
    </div>
  )
}

const mapStateToProps = state => ({

})
const mapDispatchToProps = {
    closeApp: ubuntuActions.closeApp
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowControls)