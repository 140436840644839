import { useEffect, useRef, useState } from "react";

export function useDragging() {
    const [isDragging, setIsDragging] = useState(false);
    const [pos, setPos] = useState({ x: 0, y: 0 });
    const ref = useRef(null);
    const controlRef = useRef(null)
  
    function onMouseMove(e) {
      if (!isDragging) return;
      setPos({
        x: e.x - ref.current.offsetWidth / 2,
        y: e.y - ref.current.offsetHeight / ref.current.offsetHeight - 40 > 0 ? e.y - ref.current.offsetHeight / ref.current.offsetHeight - 70  : 0,
      });
      e.stopPropagation();
      e.preventDefault();
    }
  
    function onMouseUp(e) {
      setIsDragging(false);
      controlRef.current.style.cursor = 'auto'
      e.stopPropagation();
      e.preventDefault();
      
    }
  
    function onMouseDown(e) {
      if (e.button !== 0) return;
      setIsDragging(true);
      controlRef.current.style.cursor = 'grab'
    //   setPos({
    //     x: e.x - ref.current.offsetWidth / 2,
    //     y: e.y - ref.current.offsetHeight / ref.current.offsetHeight - 70,
    //   });
  
    //   e.stopPropagation();
    //   e.preventDefault();
    }
  
    // When the element mounts, attach an mousedown listener
    useEffect(() => {
        controlRef.current.addEventListener("mousedown", onMouseDown);
  
      return () => {
        controlRef.current.removeEventListener("mousedown", onMouseDown);
      };
    }, [controlRef.current]);
  
    // Everytime the isDragging state changes, assign or remove
    // the corresponding mousemove and mouseup handlers
    useEffect(() => {
      if (isDragging) {
        document.addEventListener("mouseup", onMouseUp);
        document.addEventListener("mousemove", onMouseMove);
      } else {
        document.removeEventListener("mouseup", onMouseUp);
        document.removeEventListener("mousemove", onMouseMove);
      }
      return () => {
        document.removeEventListener("mouseup", onMouseUp);
        document.removeEventListener("mousemove", onMouseMove);
      };
    }, [isDragging]);
  
    return [controlRef ,ref, pos.x, pos.y, isDragging];
  }

