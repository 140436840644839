
const publicApi = {
 setLang: (data) => (dispatch) => {
  localStorage.setItem('lang', data);
  dispatch({type: 'SET_LANG', payload: data});
 },
 loadData: () => (dispatch) => {
   if(localStorage.getItem('lang')){
    dispatch({type: 'SET_LANG', payload : localStorage.getItem('lang')});
   }
 },
 checkLayoutVersion: () => async (dispatch) => {
  if (window.innerWidth <= 900) {
    dispatch({ type: "SET_MOBILE", payload: true });
  } else {
    dispatch({ type: "SET_MOBILE", payload: false });
  }
  window.addEventListener(
    "resize",
    function () {
      if (window.innerWidth <= 900) {
        dispatch({ type: "SET_MOBILE", payload: true });
      } else {
        dispatch({ type: "SET_MOBILE", payload: false });
      }
    },
    true
  );
},
};

export default publicApi;
