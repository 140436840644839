import React, { useEffect, useState } from 'react'
import WindowEml from '../WindowEml'
import WindowControls from '../WindowControls'
import {RiErrorWarningFill} from 'react-icons/ri'

const Alert = ({item = {
    title: 'asdasd',
    message: 'asdasdasd!'
}}) => {

    const [show, setShow] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 100)
    }, [])
  return (
    <WindowEml item={item} zIndex={100} title={item.data.title} className={`!w-1/3 h-max transition-opacity duration-500 ${show ? 'opacity-100' : 'opacity-0'} `} >
        <div className='p-4 bg-ubuntuGray/70 flex flex-col items-center'>
            <p className='text-6xl text-orange-400'><RiErrorWarningFill /></p>
            <h1 className='text-3xl mt-2 mb-4 text-white font-bold'>{item.data.title}</h1>
            <p className='text-gray-300'>{item.data.message}</p>
        </div>
    </WindowEml>
  )
}

export default Alert

