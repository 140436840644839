const ubuntuActions = {
    openApp: (data) => async dispatch => {
        await dispatch({type: 'OPEN_APP', payload: data})
    },
    closeApp: (data) => async dispatch => {
        await dispatch({type: 'CLOSE_APP', payload: data})
    },
    setMenu: (data) => async dispatch => {
        await dispatch({type: 'SET_MENU', payload: data})
    }
}
export default ubuntuActions