import React from "react";
import { connect } from "react-redux";
import ubuntuBack from '../../assets/img/2587476.webp'
import TopNav from "../../Components/TopNav";
import SideBar from "../../Components/sidebar";
import MainCanvas from "../../Components/MainCanvas";
import { ubuntuActions } from "../../redux/actions";

const XpHome = ({menu, menuList, lang, setMenu, openApp, appsList}) => {
    return(
        <div className="w-full h-screen flex bg-fixed flex-col overflow-hidden" style={{background: menu ? '#333333' : `url(${ubuntuBack})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: "center", backgroundAttachment: 'fixed', }}>
                <TopNav />
                <div className="w-full flex flex-1 overflow-hidden">
                    
                <SideBar />
                <div className="w-full h-full flex-1 relative overflow-y-hidden flex flex-col items-center">
                <MainCanvas />
                <div className={` w-4/5 h-2/4 absolute grid grid-cols-6 gap-6  ${menu ? 'bottom-20' : '-bottom-full'}`}>
                    {
                        menuList?.map(item => (
                            <div
                                onClick={() => {
                                    setMenu(false)
                                    openApp({
                                        id: appsList?.length + 1,
                                        relatedApp: 'Google Chrome',
                                        data: {
                                            link: item.link
                                        }
                                    })
                                }}
                            className="w-full  cursor-pointer hover:bg-gray-600/70 hover:shadow-2xl rounded-2xl drop-shadow flex flex-col items-center ">
                                <div className="w-2/3 h-24 flex items-center justify-center rounded-xl overflow-hidden">
                                        <img src={item.image} className="drop-shadow-lg rounded-xl" />
                                    </div>
                                    <h3 className="text-sm text-gray-200 mt-2">{lang == 'English' ? item.title : item.faTitle}</h3>
                            </div>
                        ))
                    }
                </div>
                </div>
                    </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    lang: state.publicApi.lang,
    menu: state.ubuntuState.menu,
    menuList: state.ubuntuState.menuList,
    appsList: state.ubuntuState.appsList
 });
 
 const mapDispatchToProps = {
  setMenu: ubuntuActions.setMenu,
  openApp: ubuntuActions.openApp
     
 };
  export default connect(mapStateToProps, mapDispatchToProps)(XpHome);