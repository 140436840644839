import ronix from '../assets/img/ronix.webp'
import vista from '../assets/img/logo.webp'
import liapood from '../assets/img/liapood.png'
import roomDemo from '../assets/img/Frame.svg'
import noav from '../assets/img/logo-3d.svg'
import snug from '../assets/img/snugLogo.svg'
import dpay from '../assets/img/dpay.svg'
import dglb from '../assets/img/logoDg.png'

export const portfolioData = [
    {
        id: 1,
        title: 'Ronix 3d Landing Page',
        faTitle: 'لندینگ سه بعدی رونیکس',
        link: 'https://ronix.noav.dev',
        image: ronix
    },
    {
        id: 6,
        title: '3D Room Configurator',
        faTitle: 'کانفیگوریتور اتاق',
        link: 'https://roomdemo.noav.dev',
        image: roomDemo
    },
    {
        id: 5,
        title: 'Noav Studio',
        faTitle: 'نوآو استودیو',
        link: 'https://new.noav.studio/',
        image: noav
    },
    {
        id: 4,
        title: 'Snug 3d Landing',
        faTitle: 'لندینگ سه بعدی اسناگ',
        link: 'https://snug.noav.dev/',
        image: snug
    },
    {
        id: 7,
        title: 'D-Pay',
        faTitle: 'خدمات پزشکی دی پی',
        link: 'https://d-pay.faramouj.com/',
        image: dpay
    },
    {
        id: 2,
        title: 'VistaSeir',
        faTitle: 'ویستاسیر',
        link: 'https://vistaseir.faramouj.com',
        image: vista
    },
    {
        id: 9,
        title: 'DigiLogBook',
        faTitle: 'دیجی لاگ بوک',
        link: 'https://app.digilogbook.ir/',
        image: dglb
    },
    {
        id: 3,
        title: 'LiaPood',
        faTitle: 'لیاپود',
        link: 'https://liapood.faramouj.com',
        image: liapood
    },


]
