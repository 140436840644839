import React from "react";
import WindowEml from "../WindowEml";
import resume from "../../assets/files/pedram-keshavarzi-resume.pdf";

const DocReader = ({ item, zIndex }) => {
  return item?.id ? (
    <WindowEml item={item} title={"Document Reader"} zIndex={zIndex}>
      <embed src={resume} className="w-full h-full" />
    </WindowEml>
  ) : null;
};

export default DocReader;
