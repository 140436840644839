import React, { useEffect, useRef, useState } from "react";

const Game = () => {
  const [score, setScore] = useState(0);
  const [show, setShow] = useState(0);
  const [style, setStyle] = useState({
    width: 200,
    height: 200,
    background: "green",
    top: 300,
    left: 400,
  });
  const ref = useRef(null);
  const colors = [
    "red",
    "yellow",
    "green",
    "blue",
    "white",
    "orange",
    "purple",
    "pink",
    "gray",
    "#e34ef4",
  ];

  useEffect(() => {
    setTimeout(() => {
      const wid = Math.random() * 100;
      setStyle({
        width: wid + "px",
        height: wid + "px",
        top: Math.random() * 1000 + "px",
        left: Math.random() * 2000 + "px",
        background: colors[Math.floor(Math.random() * 10)],
      });
      console.log({
        width: wid + "px",
        height: wid + "px",
        top: Math.random() * 100 + "px",
        left: Math.random() * 1000 + "px",
        background: colors[Math.floor(Math.random() * 10)],
      });
    }, Math.random() * 2000);
  }, [style]);

  return (
    <div className="relative w-full h-screen bg-black cursor-cell">
      <div className="absolute top-4 right-4 p-4 bg-white/40 rounded-lg">
        Score: {score}
      </div>
      <div
        ref={ref}
        onClick={() => setScore(score + 1)}
        className="absolute z-[999]"
        style={style}
      ></div>
    </div>
  );
};

export default Game;
