import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import {CiBatteryFull} from 'react-icons/ci'
import {AiFillSound, AiOutlineWifi} from 'react-icons/ai'
import UbuntuSelect from '../UbuntuSelect';
import publicApi from '../../redux/actions/public';

const TopNav = ({lang, setLang}) => {
    const [time, setTime] = useState({hours: new Date().getHours(), minutes: new Date().getMinutes()});
    const [date, setDate] = useState(new Date().toLocaleDateString(lang == 'English' ? 'en-us' : 'fa-IR'));

    const [chooseLangModal,setChooseLangModal] = useState(false)

    useEffect(() => {
        setInterval(() => {
            setTime({hours: new Date().getHours(), minutes: new Date().getMinutes()});
        }, 1000);
    }, [])
    
    const langs = [
        {
            title: 'en',
            id: 'English'
        },
        {
            title: 'فا',
            id: 'Farsi'
        }
    ]

  return (
    <div className='w-full h-6 bg-gray-900 flex items-center justify-between px-4 text-white'>
            <p className='text-white text-sm'>
                Activities
            </p>
            <div className='flex items-center'>
           
            <p>
                {time.hours.toLocaleString(lang == 'English' ? 'en-us' : 'fa-IR')}:{time.minutes.toLocaleString(lang == 'English' ? 'en-us' : 'fa-IR')}
            </p>
            <p className='ml-2'>
                {date}
            </p>
            </div>

            <div className='flex items-center rtl '>
            {
                        chooseLangModal && <UbuntuSelect value={langs.find(itm => itm.id == lang)?.id} list={langs} closeModal={() => setChooseLangModal(false)} keyOfOption={'id'} className={'w-40 top-7 right-6'} onChange={e => {
                            
                            setLang(e)
                           
                        }} />
                    }
                <p className='mx-1 -rotate-90'><CiBatteryFull /></p>
                <p className='mx-1'><AiFillSound /></p>
                <p className='mx-1'><AiOutlineWifi /></p>

                <p className='mr-4 px-2 rounded-full cursor-pointer hover:bg-gray-600' onClick={() => setChooseLangModal(!chooseLangModal)} >
                  
                        {langs.find(itm => itm.id == lang)?.title}
                </p>
            </div>

    </div>
  )
}

const mapStateToProps = state => ({
    lang: state.publicApi.lang
})
const mapDispatchToProps = {
    setLang: publicApi.setLang
}   

export default connect(mapStateToProps, mapDispatchToProps)(TopNav)