import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { publicApi } from "./redux/actions";
import ChooseLang from "./view/chooseLang";
import XpHome from "./view/xp";
import DocReader from "./Components/DocReader";
import MobilePage from "./view/Mobile";
import Game from "./view/game";

const Router = ({ lang, loadData, checkLayoutVersion, isMobile }) => {
  useEffect(() => {
    loadData();
    checkLayoutVersion();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          exact
          element={
            isMobile ? (
              <MobilePage />
            ) : lang == null ? (
              <ChooseLang />
            ) : (
              <XpHome />
            )
          }
        />
        <Route path="/game" exact element={<Game />} />
      </Routes>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  lang: state.publicApi.lang,
  isMobile: state.publicApi.isMobile,
});

const mapDispatchToProps = {
  loadData: publicApi.loadData,
  checkLayoutVersion: publicApi.checkLayoutVersion,
};
export default connect(mapStateToProps, mapDispatchToProps)(Router);
