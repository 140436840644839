import React, { useEffect, useState } from 'react'

const UbuntuSelect = ({
    onChange,
    list,
    value,
    keyOfOption,
    valueOfOption = 'id',
    className,
    closeModal
}) => {

    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShowModal(true)
        }, 100)
    }, [])
  return (
    <div className={`rounded-xl absolute p-2 border border-gray-500 bg-ubuntuGray ${className} ${showModal ? 'opacity-100' : 'opacity-0'} `} style={{zIndex: 1000}}>
        {
            list?.map(item => (
                <div className='w-full p-1 ltr text-left hover:bg-gray-500 rounded-lg flex items-center cursor-pointer' onClick={() => {onChange(item[valueOfOption]); closeModal()}}>
                        {
                            value == item[valueOfOption] ? <p className='w-2 h-2 bg-white rounded-full'></p> : ''
                        }
                        <p className='w-full'>{item[keyOfOption]}</p>
                </div>
            ))
        }
    </div>
  )
}

export default UbuntuSelect